.Purchase .PopupForm__ball--1 {
  top: -20px;
  right: 80px;
  width: 40px;
  height: 40px;
  filter: blur(8px); }
  @media (max-width: 640px) {
    .Purchase .PopupForm__ball--1 {
      right: 40px; } }

.Purchase .PopupForm__ball--2 {
  top: 120px;
  left: -80px;
  width: 120px;
  height: 120px;
  filter: blur(4px); }
  @media (max-width: 640px) {
    .Purchase .PopupForm__ball--2 {
      top: 96px; } }

.Purchase .PopupForm__ball--3 {
  bottom: -40px;
  right: -80px;
  width: 160px;
  height: 160px;
  filter: blur(16px); }
