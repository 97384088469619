@media (min-width: 1024px) {
  .CoursePage .Author {
    width: 29.73%; } }

@media (max-width: 640px) {
  .CoursePage .Author__header {
    padding: 2px 0;
    margin-bottom: 24px; } }

@media (min-width: 641px) {
  .CoursePage .Author__header {
    padding: 3px 0; } }

@media (min-width: 641px) and (max-width: 1023px) {
  .CoursePage .Author__header {
    margin-bottom: 32px; } }

@media (min-width: 1024px) {
  .CoursePage .Author__header {
    margin-bottom: 48px; } }

.CoursePage .Author__title {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.3; }
  @media (max-width: 640px) {
    .CoursePage .Author__title {
      font-size: 28px; } }

@media (min-width: 641px) and (max-width: 1023px) {
  .CoursePage .Author__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; } }

.CoursePage .Author__videos {
  display: flex;
  align-items: stretch;
  justify-content: space-between; }
  @media (max-width: 640px) {
    .CoursePage .Author__videos {
      margin-bottom: 16px; } }
  @media (min-width: 641px) and (max-width: 1023px) {
    .CoursePage .Author__videos {
      width: 47.56%; } }
  @media (min-width: 1024px) {
    .CoursePage .Author__videos {
      margin-bottom: 32px; } }
  .CoursePage .Author__videos_left {
    width: 64.4%; }
    @media (min-width: 481px) and (max-width: 640px) {
      .CoursePage .Author__videos_left {
        width: 65%; } }
    @media (min-width: 641px) {
      .CoursePage .Author__videos_left {
        width: 64.77%; } }
  .CoursePage .Author__videos_right {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30.5%; }
    @media (min-width: 481px) and (max-width: 640px) {
      .CoursePage .Author__videos_right {
        width: 31.3%; } }
    @media (min-width: 641px) {
      .CoursePage .Author__videos_right {
        width: 30.68%; } }

.CoursePage .Author__video {
  overflow: hidden;
  position: relative;
  display: block;
  border-radius: 12px;
  cursor: pointer;
  -webkit-mask-image: -webkit-radial-gradient(white, black); }
  @media (hover: hover) {
    .CoursePage .Author__video:hover .Author__video_bg, .CoursePage .Author__video:active .Author__video_bg {
      transform: scale(1.05); } }
  .CoursePage .Author__video--big {
    width: 100%;
    height: 0;
    padding-top: 150.4%; }
    @media (min-width: 481px) and (max-width: 640px) {
      .CoursePage .Author__video--big {
        padding-top: 150.2%; } }
    @media (min-width: 641px) {
      .CoursePage .Author__video--big {
        padding-top: 149.2%; } }
  .CoursePage .Author__video--small {
    width: 100%;
    height: 0;
    padding-top: 150%; }
  .CoursePage .Author__video_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    transition: transform ease-in-out 0.15s; }
  .CoursePage .Author__video_button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

@media (min-width: 641px) and (max-width: 1023px) {
  .CoursePage .Author__info {
    width: 47.56%; } }

.CoursePage .Author__description {
  font-size: 17px;
  line-height: 1.74;
  margin-bottom: 16px; }
  @media (max-width: 640px) {
    .CoursePage .Author__description {
      font-size: 15px;
      line-height: 1.6; } }
  @media (min-width: 641px) {
    .CoursePage .Author__description {
      margin-bottom: 32px; } }

.CoursePage .Author__network {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #FFFFFF; }
  .CoursePage .Author__network:not(:last-of-type) {
    margin-bottom: 16px; }
  @media (hover: hover) {
    .CoursePage .Author__network:hover .Author__network_icon, .CoursePage .Author__network:active .Author__network_icon {
      background-color: #27273D; }
    .CoursePage .Author__network:hover .Author__network_chevron, .CoursePage .Author__network:active .Author__network_chevron {
      background-color: #27273D;
      transform: translateX(12px); } }
  .CoursePage .Author__network_icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    border-radius: 8px;
    background-color: #1F1F33;
    transition: background-color ease-in-out 0.15s; }
  .CoursePage .Author__network_info {
    font-size: 17px;
    line-height: 1.74;
    max-width: calc(100% - 84px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
    @media (max-width: 640px) {
      .CoursePage .Author__network_info {
        font-size: 15px;
        line-height: 1.6; } }
  .CoursePage .Author__network_chevron {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    transition: transform ease-in-out 0.15s, background-color ease-in-out 0.15s; }
