.PopupWrap {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .PopupWrap__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.92);
    transition-property: opacity;
    transition-timing-function: ease-in-out; }
  .PopupWrap__inner {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    cursor: pointer; }
    @media (max-width: 480px) {
      .PopupWrap__inner {
        align-items: flex-start !important; } }
  .PopupWrap__window {
    width: 100%; }
    .PopupWrap__window > div {
      margin: 0 auto; }

.popup-wrap-enter .PopupWrap__bg,
.popup-wrap-enter .PopupWrap__window {
  opacity: 0; }

.popup-wrap-enter-active .PopupWrap__bg,
.popup-wrap-enter-active .PopupWrap__window {
  opacity: 1; }

@media (min-width: 641px) {
  .popup-wrap-enter-active .PopupWrap__window--scale-up {
    animation-name: scaleUp; } }

.popup-wrap-exit-active .PopupWrap__bg,
.popup-wrap-exit-active .PopupWrap__window {
  opacity: 0; }

@keyframes scaleUp {
  0% {
    transform: translateY(56px) scale(0.95); }
  76% {
    transform: translateY(0) scale(1.01); }
  100% {
    transform: translateY(0) scale(1); } }
