.CoursePage .Faq {
  padding-right: 24px;
  padding-left: 24px;
  box-sizing: border-box;
  padding-top: 48px; }
  @media (min-width: 481px) and (max-width: 640px) {
    .CoursePage .Faq {
      padding-right: 72px;
      padding-left: 72px; } }
  @media (min-width: 641px) and (max-width: 1023px) {
    .CoursePage .Faq {
      padding-right: 72px;
      padding-left: 72px; } }
  @media (min-width: 1024px) {
    .CoursePage .Faq {
      padding-right: 128px;
      padding-left: 128px; } }
  @media (min-width: 1024px) and (max-width: 1199px) {
    .CoursePage .Faq {
      padding-right: 72px;
      padding-left: 72px; } }
  @media (min-width: 481px) and (max-width: 640px) {
    .CoursePage .Faq {
      padding-top: 72px; } }
  @media (min-width: 641px) and (max-width: 1023px) {
    .CoursePage .Faq {
      padding-top: 98px; } }
  @media (min-width: 1024px) {
    .CoursePage .Faq {
      padding-top: 128px; } }
  .CoursePage .Faq__inner {
    width: 100%;
    margin: 0 auto; }
    @media (min-width: 1024px) {
      .CoursePage .Faq__inner {
        max-width: 1184px; } }
  @media (max-width: 640px) {
    .CoursePage .Faq__header {
      padding: 2px 0;
      margin-bottom: 24px; } }
  @media (min-width: 641px) {
    .CoursePage .Faq__header {
      padding: 3px 0; } }
  @media (min-width: 641px) and (max-width: 1023px) {
    .CoursePage .Faq__header {
      margin-bottom: 32px; } }
  @media (min-width: 1024px) {
    .CoursePage .Faq__header {
      margin-bottom: 48px; } }
  @media (min-width: 641px) and (max-width: 1023px) {
    .CoursePage .Faq__header {
      margin-bottom: 48px; } }
  .CoursePage .Faq__title {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.3; }
    @media (max-width: 640px) {
      .CoursePage .Faq__title {
        font-size: 28px; } }
  .CoursePage .Faq__question:not(:last-of-type) {
    margin-bottom: 32px; }
  @media (min-width: 1024px) {
    .CoursePage .Faq__question {
      max-width: calc(29.73% * 2 + 5.4%); } }
  .CoursePage .Faq__question_title {
    font-size: 19px;
    line-height: 1.7;
    font-weight: 500;
    color: #FFFFFF; }
    @media (max-width: 640px) {
      .CoursePage .Faq__question_title {
        font-size: 17px;
        line-height: 1.6; } }
    @media (min-width: 641px) {
      .CoursePage .Faq__question_title {
        margin-bottom: 4px; } }
  .CoursePage .Faq__question_answer {
    font-size: 17px;
    line-height: 1.74;
    color: rgba(235, 235, 255, 0.64); }
    @media (max-width: 640px) {
      .CoursePage .Faq__question_answer {
        font-size: 15px;
        line-height: 1.6; } }
