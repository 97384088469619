.SignIn__link {
  position: relative;
  color: rgba(235, 235, 255, 0.64);
  transition: color ease-in-out 0.15s; }
  .SignIn__link:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    opacity: .32;
    background-color: rgba(235, 235, 255, 0.64);
    transition: opacity ease-in-out 0.15s; }
  @media (hover: hover) {
    .SignIn__link:hover, .SignIn__link:active {
      color: #FFFFFF; }
      .SignIn__link:hover:after, .SignIn__link:active:after {
        opacity: .64; } }

.SignIn__error_button {
  font-size: 17px;
  line-height: 1.74;
  font-weight: 500;
  line-height: 48px !important;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  box-sizing: border-box;
  border-radius: 100px;
  color: #FFFFFF;
  z-index: 1;
  position: relative;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
  background-image: linear-gradient(135deg, #9580FF 0%, #1D53BF 100%); }
  @media (max-width: 640px) {
    .SignIn__error_button {
      font-size: 15px;
      line-height: 1.6; } }
  .SignIn__error_button:before {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    opacity: 0;
    background-image: linear-gradient(135deg, #AA99FF 0%, #3060BF 100%);
    transition: opacity ease-in-out 0.15s; }
  @media (hover: hover) {
    .SignIn__error_button:hover:before, .SignIn__error_button:active:before {
      opacity: 1; } }
  @media (max-width: 640px) {
    .SignIn__error_button {
      margin-top: 24px;
      padding: 0 24px; } }
  @media (min-width: 641px) {
    .SignIn__error_button {
      margin-top: 32px;
      padding: 0 32px; } }

.SignIn .PopupForm__ball--1 {
  top: -40px;
  right: -80px;
  width: 120px;
  height: 120px;
  filter: blur(16px); }

.SignIn .PopupForm__ball--2 {
  top: 160px;
  left: -8px;
  width: 40px;
  height: 40px;
  filter: blur(8px); }

.SignIn .PopupForm__ball--3 {
  bottom: -120px;
  left: 80px;
  width: 160px;
  height: 160px;
  filter: blur(8px); }
