.CoursePage {
  background-color: #151529; }
  .CoursePage__content {
    z-index: 1;
    position: relative; }
  .CoursePage__balls {
    display: none; }
    @media (min-width: 1024px) {
      .CoursePage__balls {
        overflow: hidden;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }
    .CoursePage__balls_inner {
      position: relative;
      height: 100%;
      width: 100%;
      margin: 0 auto; }
      @media (min-width: 1024px) {
        .CoursePage__balls_inner {
          max-width: 1184px; } }
  .CoursePage__ball {
    position: absolute;
    border-radius: 50%;
    background: radial-gradient(100% 100% at 0% 0%, #9580FF 0%, #1D38BF 100%); }
    .CoursePage__ball--1 {
      z-index: 1;
      top: -38px;
      left: 50%;
      width: 80px;
      height: 80px;
      margin-left: -360px;
      filter: blur(8px); }
    .CoursePage__ball--2 {
      top: 820px;
      right: -164px;
      width: 120px;
      height: 120px;
      filter: blur(4px); }
    .CoursePage__ball--3 {
      top: 1575px;
      left: -171px;
      width: 80px;
      height: 80px;
      filter: blur(8px); }
    .CoursePage__ball--4 {
      top: 1922px;
      right: 152px;
      width: 80px;
      height: 80px; }
    .CoursePage__ball--5 {
      top: 2300px;
      right: 509px;
      width: 40px;
      height: 40px;
      filter: blur(8px); }
    .CoursePage__ball--6 {
      top: 2583px;
      left: -85px;
      width: 120px;
      height: 120px;
      filter: blur(4px); }
    .CoursePage__ball--7 {
      bottom: 1204px;
      right: 346px;
      width: 160px;
      height: 160px;
      filter: blur(16px); }
    .CoursePage__ball--8 {
      bottom: 771px;
      right: 172px;
      width: 40px;
      height: 40px;
      filter: blur(8px); }
    .CoursePage__ball--9 {
      bottom: 36px;
      right: 392px;
      width: 120px;
      height: 120px;
      filter: blur(8px); }
