.DraftsOffers__offer {
  display: flex;
  align-items: center; }
  .DraftsOffers__offer:not(:last-of-type) {
    margin-bottom: 12px; }
  @media (min-width: 641px) {
    .DraftsOffers__offer:not(:last-of-type) {
      margin-bottom: 16px; } }
  .DraftsOffers__offer .Icon {
    margin-right: 12px; }
  .DraftsOffers__offer_text {
    font-size: 17px;
    line-height: 1.74;
    width: calc(100% - 36px);
    color: #FFFFFF; }
    @media (max-width: 640px) {
      .DraftsOffers__offer_text {
        font-size: 15px;
        line-height: 1.6; } }
