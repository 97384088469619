.DraftsPage {
  overflow-x: hidden;
  background-color: #151529; }
  .DraftsPage__content {
    z-index: 2;
    box-sizing: border-box;
    position: relative;
    min-height: 100vh;
    padding-top: 64px; }
    @media (max-width: 480px) {
      .DraftsPage__content {
        padding-bottom: 140px; }
        @supports (padding-bottom: constant(safe-area-inset-bottom)) {
          .DraftsPage__content {
            padding-bottom: calc(140px + constant(safe-area-inset-bottom)); } }
        @supports (padding-bottom: env(safe-area-inset-bottom)) {
          .DraftsPage__content {
            padding-bottom: calc(140px + env(safe-area-inset-bottom)); } }
        .DraftsPage__content .Footer {
          margin-top: 24px; } }
    @media (min-width: 481px) and (max-width: 640px) {
      .DraftsPage__content {
        padding-bottom: 136px; }
        @supports (padding-bottom: constant(safe-area-inset-bottom)) {
          .DraftsPage__content {
            padding-bottom: calc(136px + constant(safe-area-inset-bottom)); } }
        @supports (padding-bottom: env(safe-area-inset-bottom)) {
          .DraftsPage__content {
            padding-bottom: calc(136px + env(safe-area-inset-bottom)); } } }
    @media (min-width: 641px) and (max-width: 1023px) {
      .DraftsPage__content {
        padding-bottom: 168px; }
        @supports (padding-bottom: constant(safe-area-inset-bottom)) {
          .DraftsPage__content {
            padding-bottom: calc(168px + constant(safe-area-inset-bottom)); } }
        @supports (padding-bottom: env(safe-area-inset-bottom)) {
          .DraftsPage__content {
            padding-bottom: calc(168px + env(safe-area-inset-bottom)); } } }
    @media (min-width: 641px) {
      .DraftsPage__content {
        padding-top: 96px; } }
    @media (min-width: 1024px) {
      .DraftsPage__content {
        padding-bottom: 224px; }
        @supports (padding-bottom: constant(safe-area-inset-bottom)) {
          .DraftsPage__content {
            padding-bottom: calc(224px + constant(safe-area-inset-bottom)); } }
        @supports (padding-bottom: env(safe-area-inset-bottom)) {
          .DraftsPage__content {
            padding-bottom: calc(224px + env(safe-area-inset-bottom)); } } }
  .DraftsPage__body {
    padding-right: 24px;
    padding-left: 24px;
    box-sizing: border-box; }
    @media (min-width: 481px) and (max-width: 640px) {
      .DraftsPage__body {
        padding-right: 72px;
        padding-left: 72px; } }
    @media (min-width: 641px) and (max-width: 1023px) {
      .DraftsPage__body {
        padding-right: 72px;
        padding-left: 72px; } }
    @media (min-width: 1024px) {
      .DraftsPage__body {
        padding-right: 128px;
        padding-left: 128px; } }
    @media (min-width: 1024px) and (max-width: 1199px) {
      .DraftsPage__body {
        padding-right: 72px;
        padding-left: 72px; } }
    @media (min-width: 1024px) {
      .DraftsPage__body {
        display: flex;
        align-items: flex-start;
        justify-content: space-between; } }
  .DraftsPage__balls {
    overflow: hidden;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .DraftsPage__balls_inner {
      position: relative;
      height: 100%;
      width: 100%;
      margin: 0 auto; }
      @media (min-width: 1024px) {
        .DraftsPage__balls_inner {
          max-width: 1184px; } }
  .DraftsPage__ball {
    position: absolute;
    border-radius: 50%;
    background: radial-gradient(100% 100% at 0% 0%, #9580FF 0%, #1D38BF 100%); }
    .DraftsPage__ball--1 {
      z-index: 1;
      top: -20px;
      left: 50%;
      width: 40px;
      height: 40px;
      margin-left: -36px;
      filter: blur(8px); }
      @media (min-width: 481px) and (max-width: 640px) {
        .DraftsPage__ball--1 {
          margin-left: -72px; } }
      @media (min-width: 641px) and (max-width: 1023px) {
        .DraftsPage__ball--1 {
          margin-left: -120px; } }
      @media (min-width: 1024px) {
        .DraftsPage__ball--1 {
          margin-left: -248px; } }
    .DraftsPage__ball--2 {
      z-index: 1;
      top: 320px;
      right: -40px;
      width: 80px;
      height: 80px;
      filter: blur(8px); }
    .DraftsPage__ball--3 {
      z-index: 1;
      top: 640px;
      left: -80px;
      width: 120px;
      height: 120px;
      filter: blur(4px); }
      @media (max-width: 480px) {
        .DraftsPage__ball--3 {
          display: none; } }
  .DraftsPage .Footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
