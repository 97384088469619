.CoursePage .Reviews {
  overflow: hidden;
  padding-top: 48px;
  user-select: none;
  padding-right: 24px;
  padding-left: 24px;
  box-sizing: border-box; }
  @media (min-width: 481px) and (max-width: 640px) {
    .CoursePage .Reviews {
      padding-right: 72px;
      padding-left: 72px; } }
  @media (min-width: 641px) and (max-width: 1023px) {
    .CoursePage .Reviews {
      padding-right: 72px;
      padding-left: 72px; } }
  @media (min-width: 1024px) {
    .CoursePage .Reviews {
      padding-right: 128px;
      padding-left: 128px; } }
  @media (min-width: 1024px) and (max-width: 1199px) {
    .CoursePage .Reviews {
      padding-right: 72px;
      padding-left: 72px; } }
  @media (min-width: 481px) and (max-width: 1023px) {
    .CoursePage .Reviews {
      padding-top: 72px; } }
  @media (min-width: 1024px) {
    .CoursePage .Reviews {
      padding-top: 96px; }
      .CoursePage .Reviews .alice-carousel__stage-item {
        transition: opacity ease-in-out 500ms;
        opacity: 0; }
        .CoursePage .Reviews .alice-carousel__stage-item.__active {
          opacity: 1; } }
  .CoursePage .Reviews__inner {
    width: 100%;
    margin: 0 auto; }
    @media (min-width: 1024px) {
      .CoursePage .Reviews__inner {
        max-width: 1184px; } }
  @media (max-width: 640px) {
    .CoursePage .Reviews__header {
      padding: 2px 0;
      margin-bottom: 24px; } }
  @media (min-width: 641px) {
    .CoursePage .Reviews__header {
      padding: 3px 0; } }
  @media (min-width: 641px) and (max-width: 1023px) {
    .CoursePage .Reviews__header {
      margin-bottom: 32px; } }
  @media (min-width: 1024px) {
    .CoursePage .Reviews__header {
      margin-bottom: 48px; } }
  @media (min-width: 641px) and (max-width: 1023px) {
    .CoursePage .Reviews__header {
      margin-bottom: 48px; } }
  .CoursePage .Reviews__title {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.3; }
    @media (max-width: 640px) {
      .CoursePage .Reviews__title {
        font-size: 28px; } }
  @media (max-width: 480px) {
    .CoursePage .Reviews__list {
      margin-right: -24px;
      margin-left: -24px; } }
  @media (min-width: 481px) and (max-width: 1023px) {
    .CoursePage .Reviews__list {
      margin-right: -72px;
      margin-left: -72px; } }
  @media (min-width: 1024px) {
    .CoursePage .Reviews__list {
      margin-right: -479px;
      margin-left: -415px; } }
  .CoursePage .Reviews__list--transition-off .alice-carousel__stage-item {
    transition: none !important; }
  .CoursePage .Reviews .alice-carousel__next-btn,
  .CoursePage .Reviews .alice-carousel__prev-btn {
    display: none; }
    @media (min-width: 1024px) {
      .CoursePage .Reviews .alice-carousel__next-btn,
      .CoursePage .Reviews .alice-carousel__prev-btn {
        position: absolute;
        top: -99px;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        transform: translateX(-50%);
        cursor: pointer; } }
  @media (min-width: 1024px) and (hover: hover) {
    .CoursePage .Reviews .alice-carousel__next-btn:hover .Reviews__slider_arrow_bg, .CoursePage .Reviews .alice-carousel__next-btn:active .Reviews__slider_arrow_bg,
    .CoursePage .Reviews .alice-carousel__prev-btn:hover .Reviews__slider_arrow_bg,
    .CoursePage .Reviews .alice-carousel__prev-btn:active .Reviews__slider_arrow_bg {
      background-color: #27273D;
      transform: scale(1.2); } }
  @media (min-width: 1024px) and (max-width: 1199px) {
    .CoursePage .Reviews .alice-carousel__prev-btn {
      margin-left: calc((100vw - 144px) * .38); } }
  @media (min-width: 1200px) {
    .CoursePage .Reviews .alice-carousel__prev-btn {
      margin-left: calc((100vw - 256px) * .409); } }
  @media (min-width: 1440px) {
    .CoursePage .Reviews .alice-carousel__prev-btn {
      margin-left: 484px; } }
  @media (min-width: 1024px) and (max-width: 1199px) {
    .CoursePage .Reviews .alice-carousel__next-btn {
      margin-left: calc((100vw - 144px) * .44); } }
  @media (min-width: 1200px) {
    .CoursePage .Reviews .alice-carousel__next-btn {
      margin-left: calc((100vw - 256px) * .456); } }
  @media (min-width: 1440px) {
    .CoursePage .Reviews .alice-carousel__next-btn {
      margin-left: 540px; } }
  .CoursePage .Reviews__slider_arrow {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px; }
    .CoursePage .Reviews__slider_arrow--prev .Reviews__slider_arrow_icon {
      transform: scale(-1, 1); }
    .CoursePage .Reviews__slider_arrow_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #1F1F33;
      transition: transform ease-in-out 0.15s, background-color ease-in-out 0.15s; }
    .CoursePage .Reviews__slider_arrow_icon {
      position: relative;
      width: 12px;
      height: 16px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../../../assets/icons/chevron_16.svg"); }

.Review {
  box-sizing: border-box; }
  @media (max-width: 480px) {
    .Review {
      width: calc(80.8vw + 24px);
      padding-right: 24px; } }
  @media (min-width: 481px) and (max-width: 640px) {
    .Review {
      width: calc(70vw + 24px);
      padding-right: 24px; } }
  @media (min-width: 641px) and (max-width: 1023px) {
    .Review {
      width: calc(39vw + 32px);
      padding-right: 32px; } }
  @media (min-width: 1024px) {
    .Review {
      width: 24.44vw;
      max-width: 352px; } }
  .Review__inner {
    box-sizing: border-box;
    padding: 28px 32px 32px;
    border-radius: 12px;
    background-color: #1F1F33; }
    @media (max-width: 640px) {
      .Review__inner {
        padding: 20px 24px 24px; } }
  .Review__text {
    font-size: 17px;
    line-height: 1.74;
    margin-bottom: 28px;
    white-space: normal; }
    @media (max-width: 640px) {
      .Review__text {
        font-size: 15px;
        line-height: 1.6; } }
    @media (max-width: 640px) {
      .Review__text {
        margin-bottom: 20px; } }
  .Review__author {
    display: flex;
    align-items: center;
    height: 48px; }
    .Review__author_avatar {
      width: 48px;
      height: 48px;
      margin-right: 16px;
      border-radius: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center; }
    .Review__author_info {
      width: calc(100% - 64px);
      margin-top: -4px; }
    .Review__author_name {
      font-size: 17px;
      line-height: 1.74;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis; }
      @media (max-width: 640px) {
        .Review__author_name {
          font-size: 15px;
          line-height: 1.6; } }
    .Review__author_network {
      font-size: 15px;
      line-height: 1.5;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 1px;
      color: rgba(235, 235, 255, 0.64); }
      @media (max-width: 640px) {
        .Review__author_network {
          font-size: 13px;
          line-height: 1.4; } }
