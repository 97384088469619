.EmailField {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin: 32px auto;
  animation-fill-mode: both; }
  @media (max-width: 640px) {
    .EmailField {
      margin: 24px auto; } }
  .EmailField--error {
    animation-name: shake; }
  .EmailField__input {
    font-size: 17px;
    line-height: 1.74;
    box-sizing: border-box;
    appearance: none !important;
    width: 100%;
    min-height: 48px;
    max-height: 48px;
    padding: 0 118px 0 24px;
    margin: 0;
    color: #FFFFFF;
    border: none;
    border-radius: 100px;
    outline: none;
    background: #1F1F33;
    transition: background ease-in-out 0.15s;
    caret-color: #FFFFFF;
    -webkit-text-fill-color: #FFFFFF;
    -webkit-box-shadow: 0 0 0 2000px #1F1F33 inset;
    background-clip: content-box; }
    @media (max-width: 640px) {
      .EmailField__input {
        font-size: 15px;
        line-height: 1.6; } }
    .EmailField__input::-moz-placeholder {
      color: #EBECFF;
      opacity: .64;
      transition: opacity ease-in-out 0.15s; }
    .EmailField__input::-webkit-input-placeholder {
      color: #EBECFF;
      opacity: .64;
      transition: opacity ease-in-out 0.15s; }
    .EmailField__input:-ms-input-placeholder {
      color: #EBECFF;
      opacity: .64;
      transition: opacity ease-in-out 0.15s; }
    .EmailField__input::-ms-input-placeholder {
      color: #EBECFF;
      opacity: .64;
      transition: opacity ease-in-out 0.15s; }
    .EmailField__input::placeholder {
      color: #EBECFF;
      opacity: .64;
      transition: opacity ease-in-out 0.15s; }
    @media (max-width: 640px) {
      .EmailField__input {
        padding-right: 98px; } }
    @media (hover: hover) {
      .EmailField__input:hover {
        background: #27273D;
        -webkit-box-shadow: 0 0 0 2000px #27273D inset;
        background-clip: content-box; } }
    .EmailField__input:focus::-moz-placeholder {
      opacity: .48; }
    .EmailField__input:focus::-webkit-input-placeholder {
      opacity: .48; }
    .EmailField__input:focus:-ms-input-placeholder {
      opacity: .48; }
    .EmailField__input:focus::-ms-input-placeholder {
      opacity: .48; }
    .EmailField__input:focus::placeholder {
      opacity: .48; }
  .EmailField__submit {
    font-size: 19px;
    line-height: 1.7;
    font-weight: 500;
    line-height: 48px !important;
    top: 0;
    right: 0;
    appearance: none;
    margin: 0;
    padding: 0 32px;
    height: 48px;
    color: #FFFFFF;
    border: none;
    border-radius: 100px;
    outline: none;
    background: none;
    cursor: pointer;
    z-index: 1;
    position: relative;
    overflow: hidden;
    user-select: none;
    cursor: pointer;
    background-image: linear-gradient(135deg, #9580FF 0%, #1D53BF 100%);
    position: absolute; }
    @media (max-width: 640px) {
      .EmailField__submit {
        font-size: 17px;
        line-height: 1.6; } }
    .EmailField__submit:before {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      opacity: 0;
      background-image: linear-gradient(135deg, #AA99FF 0%, #3060BF 100%);
      transition: opacity ease-in-out 0.15s; }
    @media (hover: hover) {
      .EmailField__submit:hover:before, .EmailField__submit:active:before {
        opacity: 1; } }
    @media (max-width: 640px) {
      .EmailField__submit {
        padding-right: 24px;
        padding-left: 24px; } }
    .EmailField__submit_loader {
      width: 16px;
      height: 16px;
      margin: 0 auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../../assets/icons/loader_16.svg");
      animation: loader .7s linear infinite;
      transform-origin: center; }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0); }
  20%,
  60% {
    transform: translate3d(-8px, 0, 0); }
  40%,
  80% {
    transform: translate3d(8px, 0, 0); } }
