@media (min-width: 641px) and (max-width: 1023px) {
  .DraftsPage .Author {
    width: 360px;
    margin: 0 auto; } }

@media (min-width: 1024px) {
  .DraftsPage .Author {
    width: 29.73%; } }

.DraftsPage .Author__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  cursor: pointer;
  color: #FFFFFF; }
  .DraftsPage .Author__header_main {
    display: inline-flex;
    align-items: center;
    width: calc(100% - 44px); }
  @media (hover: hover) {
    .DraftsPage .Author__header:hover .Author__chevron {
      background-color: #27273D; } }

.DraftsPage .Author__avatar {
  width: 72px;
  height: 72px;
  margin-right: 16px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../../../assets/images/author_avatar.jpeg"); }

.DraftsPage .Author__bio {
  box-sizing: border-box;
  width: calc(100% - 88px); }

.DraftsPage .Author__name {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 2px; }
  @media (max-width: 640px) {
    .DraftsPage .Author__name {
      font-size: 20px; } }

.DraftsPage .Author__tiktok {
  font-size: 17px;
  line-height: 1.74;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  color: rgba(235, 235, 255, 0.64); }
  @media (max-width: 640px) {
    .DraftsPage .Author__tiktok {
      font-size: 15px;
      line-height: 1.6; } }

.DraftsPage .Author__chevron {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #1F1F33;
  transition: background-color ease-in-out 0.15s; }

.DraftsPage .Author__payment {
  line-height: 0;
  border-radius: 12px;
  text-align: center;
  background-color: #1F1F33; }
  @media (max-width: 640px) {
    .DraftsPage .Author__payment {
      padding: 16px; } }
  @media (min-width: 641px) {
    .DraftsPage .Author__payment {
      padding: 32px; } }

.DraftsPage .Author__cta {
  font-size: 17px;
  line-height: 1.74;
  font-weight: 500;
  line-height: 48px !important;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  text-align: center;
  box-sizing: border-box;
  border-radius: 100px;
  color: #FFFFFF; }
  @media (max-width: 640px) {
    .DraftsPage .Author__cta {
      font-size: 15px;
      line-height: 1.6; } }
  .DraftsPage .Author__cta--buy {
    z-index: 1;
    position: relative;
    overflow: hidden;
    user-select: none;
    cursor: pointer;
    background-image: linear-gradient(135deg, #9580FF 0%, #1D53BF 100%); }
    .DraftsPage .Author__cta--buy:before {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      opacity: 0;
      background-image: linear-gradient(135deg, #AA99FF 0%, #3060BF 100%);
      transition: opacity ease-in-out 0.15s; }
    @media (hover: hover) {
      .DraftsPage .Author__cta--buy:hover:before, .DraftsPage .Author__cta--buy:active:before {
        opacity: 1; } }
  .DraftsPage .Author__cta--unlocked {
    z-index: 1;
    position: relative;
    overflow: hidden;
    user-select: none;
    cursor: pointer;
    background-image: linear-gradient(135deg, #45E6B0 0%, #36B374 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none; }
    .DraftsPage .Author__cta--unlocked:before {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      opacity: 0;
      background-image: linear-gradient(135deg, #55F2BE 0%, #43BF81 100%);
      transition: opacity ease-in-out 0.15s; }
    @media (hover: hover) {
      .DraftsPage .Author__cta--unlocked:hover:before, .DraftsPage .Author__cta--unlocked:active:before {
        opacity: 1; } }
    .DraftsPage .Author__cta--unlocked .Icon {
      margin-right: 8px; }

.DraftsPage .Author__settings_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  min-width: 48px;
  height: 48px;
  margin-left: 16px;
  border-radius: 50px;
  background-color: #1F1F33;
  cursor: pointer;
  transition: background-color ease-in-out 0.15s; }
  @media (hover: hover) {
    .DraftsPage .Author__settings_btn:hover {
      background-color: #27273D; } }

.DraftsPage .Author .PaymentWays {
  margin-top: 16px; }

@media (max-width: 640px) {
  .DraftsPage .Author .DraftsOffers {
    margin-top: 24px; } }

@media (min-width: 641px) {
  .DraftsPage .Author .DraftsOffers {
    margin-top: 32px; } }

.DraftsPage .Author__unlocked {
  display: flex;
  align-items: center;
  justify-content: space-between; }
