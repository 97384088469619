.CoursePage .Trailer {
  margin-bottom: 48px; }
  @media (min-width: 481px) and (max-width: 1023px) {
    .CoursePage .Trailer {
      margin-bottom: 72px; } }
  @media (min-width: 1024px) {
    .CoursePage .Trailer {
      margin-bottom: 96px; } }
  @media (max-width: 640px) {
    .CoursePage .Trailer__header {
      padding: 2px 0;
      margin-bottom: 24px; } }
  @media (min-width: 641px) {
    .CoursePage .Trailer__header {
      padding: 3px 0; } }
  @media (min-width: 641px) and (max-width: 1023px) {
    .CoursePage .Trailer__header {
      margin-bottom: 32px; } }
  @media (min-width: 1024px) {
    .CoursePage .Trailer__header {
      margin-bottom: 48px; } }
  .CoursePage .Trailer__title {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.3; }
    @media (max-width: 640px) {
      .CoursePage .Trailer__title {
        font-size: 28px; } }
  .CoursePage .Trailer__video_wrap {
    overflow: hidden;
    border-radius: 12px;
    -webkit-mask-image: -webkit-radial-gradient(white, black); }
