.CoursePage .Promo {
  overflow: hidden;
  position: relative;
  height: 720px; }
  @media (min-width: 1024px) {
    .CoursePage .Promo {
      height: 800px; } }
  .CoursePage .Promo:before {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 120px;
    content: '';
    opacity: 0.48;
    background: linear-gradient(360deg, rgba(21, 21, 41, 0) 0%, #151529 100%); }
  .CoursePage .Promo:after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 400px;
    content: '';
    background: linear-gradient(360deg, #151529 0%, rgba(21, 21, 41, 0.5) 74.99%, rgba(21, 21, 41, 0) 100%); }
  .CoursePage .Promo__video_wrap {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden; }
  .CoursePage .Promo__video {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: calc(100vh * (16 / 9));
    /* assumes 16:9 */
    height: 100vh;
    transform: translate(-50%, -50%); }
    @media (min-width: 1024px) {
      .CoursePage .Promo__video {
        min-width: 100%;
        min-height: 800px; } }
  .CoursePage .Promo__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-right: 24px;
    padding-left: 24px;
    box-sizing: border-box; }
    @media (min-width: 481px) and (max-width: 640px) {
      .CoursePage .Promo__content {
        padding-right: 72px;
        padding-left: 72px; } }
    @media (min-width: 641px) and (max-width: 1023px) {
      .CoursePage .Promo__content {
        padding-right: 72px;
        padding-left: 72px; } }
    @media (min-width: 1024px) {
      .CoursePage .Promo__content {
        padding-right: 128px;
        padding-left: 128px; } }
    @media (min-width: 1024px) and (max-width: 1199px) {
      .CoursePage .Promo__content {
        padding-right: 72px;
        padding-left: 72px; } }
    @media (max-width: 640px) {
      .CoursePage .Promo__content {
        text-align: center; } }
  .CoursePage .Promo__inner {
    z-index: 1;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 100%;
    padding-bottom: 16px;
    width: 100%;
    margin: 0 auto; }
    @media (min-width: 1024px) {
      .CoursePage .Promo__inner {
        max-width: 1184px; } }
    @media (min-width: 641px) {
      .CoursePage .Promo__inner {
        padding-bottom: 32px; } }
  @media (min-width: 1024px) {
    .CoursePage .Promo__info {
      width: calc(29.73% * 2 + 5.4%); } }
  .CoursePage .Promo__title {
    font-size: 64px;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 8px; }
    @media (max-width: 640px) {
      .CoursePage .Promo__title {
        font-size: 40px; } }
  .CoursePage .Promo__description {
    font-size: 19px;
    line-height: 1.7;
    margin-bottom: 16px; }
    @media (max-width: 640px) {
      .CoursePage .Promo__description {
        font-size: 17px;
        line-height: 1.6; } }
    @media (min-width: 641px) {
      .CoursePage .Promo__description {
        margin-bottom: 32px; } }
  .CoursePage .Promo__cta {
    font-size: 17px;
    line-height: 1.74;
    font-weight: 500;
    line-height: 48px !important;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    box-sizing: border-box;
    border-radius: 100px;
    color: #FFFFFF; }
    @media (max-width: 640px) {
      .CoursePage .Promo__cta {
        font-size: 15px;
        line-height: 1.6; } }
    @media (max-width: 640px) {
      .CoursePage .Promo__cta {
        width: 100%; } }
    @media (min-width: 641px) {
      .CoursePage .Promo__cta {
        padding: 0 32px; } }
    .CoursePage .Promo__cta--buy {
      z-index: 1;
      position: relative;
      overflow: hidden;
      user-select: none;
      cursor: pointer;
      background-image: linear-gradient(135deg, #9580FF 0%, #1D53BF 100%); }
      .CoursePage .Promo__cta--buy:before {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        opacity: 0;
        background-image: linear-gradient(135deg, #AA99FF 0%, #3060BF 100%);
        transition: opacity ease-in-out 0.15s; }
      @media (hover: hover) {
        .CoursePage .Promo__cta--buy:hover:before, .CoursePage .Promo__cta--buy:active:before {
          opacity: 1; } }
    .CoursePage .Promo__cta--watch {
      z-index: 1;
      position: relative;
      overflow: hidden;
      user-select: none;
      cursor: pointer;
      background-image: linear-gradient(135deg, #45E6B0 0%, #36B374 100%); }
      .CoursePage .Promo__cta--watch:before {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        opacity: 0;
        background-image: linear-gradient(135deg, #55F2BE 0%, #43BF81 100%);
        transition: opacity ease-in-out 0.15s; }
      @media (hover: hover) {
        .CoursePage .Promo__cta--watch:hover:before, .CoursePage .Promo__cta--watch:active:before {
          opacity: 1; } }
  .CoursePage .Promo__payment {
    display: flex;
    align-items: center; }
    @media (max-width: 640px) {
      .CoursePage .Promo__payment {
        flex-direction: column; } }
    .CoursePage .Promo__payment .PaymentWays {
      margin-top: 24px; }
      @media (min-width: 641px) {
        .CoursePage .Promo__payment .PaymentWays {
          margin-top: 0;
          margin-left: 24px; } }
  .CoursePage .Promo__badge {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px; }
    .CoursePage .Promo__badge_inner {
      font-size: 64px;
      font-weight: 500;
      display: inline-block;
      margin-top: -50%;
      margin-left: -50%;
      padding: 8px 24px;
      text-transform: uppercase;
      color: #45E5B0;
      border: 8px solid #45E5B0;
      border-radius: 24px;
      user-select: none;
      cursor: default;
      opacity: 0;
      animation: 2100ms ease-in-out 500ms forwards badge; }
      @media (max-width: 640px) {
        .CoursePage .Promo__badge_inner {
          animation-name: badge-mobile; } }
  .CoursePage .Promo__buttons--transient {
    position: relative;
    width: 100%;
    height: 48px; }
    .CoursePage .Promo__buttons--transient .Promo__cta {
      position: absolute;
      left: 0; }
      .CoursePage .Promo__buttons--transient .Promo__cta--buy {
        top: 0;
        animation: 300ms ease-in-out 2300ms both button-disappear; }
      .CoursePage .Promo__buttons--transient .Promo__cta--watch {
        top: -100%;
        opacity: 0;
        animation: 300ms ease-in-out 2300ms both button-appear; }

@keyframes badge {
  0% {
    opacity: 0;
    transform: scale(2) rotate(8deg); }
  12.5% {
    opacity: 1;
    transform: scale(0.98) rotate(-8deg); }
  25%,
  87.5% {
    opacity: 1;
    transform: scale(1) rotate(-8deg); }
  100% {
    opacity: 0;
    transform: translateY(100px) rotate(-8deg); } }

@keyframes badge-mobile {
  0% {
    opacity: 0;
    transform: scale(1.5) rotate(8deg); }
  12.5% {
    opacity: 1;
    transform: scale(0.735) rotate(-8deg); }
  25%,
  87.5% {
    opacity: 1;
    transform: scale(0.75) rotate(-8deg); }
  100% {
    opacity: 0;
    transform: scale(0.75) translateY(100px) rotate(-8deg); } }

@keyframes button-appear {
  0% {
    opacity: 0;
    top: -100%; }
  100% {
    opacity: 1;
    top: 0; } }

@keyframes button-disappear {
  0% {
    opacity: 1;
    top: 0; }
  100% {
    opacity: 0;
    top: 100%; } }
