.PopupWrap--player .PopupWrap__bg {
  background-color: rgba(20, 20, 20, 0.92); }

@media (max-width: 480px) {
  .PopupWrap--player .PopupWrap__inner {
    align-items: flex-start !important; } }

@media (min-width: 481px) {
  .PopupWrap--player .PopupWrap__window {
    padding: 32px; } }

.Player {
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  background-color: #000000;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  animation-fill-mode: both;
  -webkit-mask-image: -webkit-radial-gradient(white, black); }
  @media (max-width: 480px) {
    .Player {
      width: 100vw;
      height: 100vh;
      border-radius: 0 0 12px 12px;
      height: calc(var(--vh, 1vh) * 100); }
      @supports (height: constant(safe-area-inset-bottom)) {
        .Player {
          height: calc(calc(var(--vh, 1vh) * 100) - constant(safe-area-inset-bottom)); } }
      @supports (height: env(safe-area-inset-bottom)) {
        .Player {
          height: calc(calc(var(--vh, 1vh) * 100) - env(safe-area-inset-bottom)); } } }
  @media (min-width: 481px) {
    .Player {
      width: 56.3vh;
      max-width: 470px;
      min-width: 320px;
      height: calc(100vh - 64px);
      min-height: 568px;
      max-height: 836px;
      border-radius: 12px; } }
  .Player__header {
    z-index: 1;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 83px;
    padding: 8px 8px 16px;
    cursor: default; }
  .Player__timelines {
    display: flex;
    margin: 0 -2px; }
  .Player__timeline {
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    height: 4px;
    margin: 0 2px;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.32);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16); }
    .Player__timeline_progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateX(-101%);
      background-color: #FFFFFF; }
  .Player__actions {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: 8px; }
  .Player__action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    cursor: pointer;
    transition: opacity ease-in-out 0.15s; }
    @media (hover: hover) {
      .Player__action:hover {
        opacity: .64; } }
  .Player__body {
    width: 100%;
    height: 100%; }
  .Player__loader {
    width: 56px;
    height: 56px;
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../../../assets/icons/loader_56.svg");
    animation: loader .7s linear infinite;
    transform-origin: center; }
    .Player__loader_wrap {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: #000000; }
  .Player__paused {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; }
  .Player__video {
    width: 101% !important;
    height: 101% !important;
    padding: 0 !important; }
  .Player__manage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .Player__prev {
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    opacity: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.3), transparent);
    transition: opacity ease-in-out 0.15s; }
    .Player__prev:hover {
      opacity: 1; }
  .Player__next {
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    height: 100%;
    opacity: 0;
    background: linear-gradient(-90deg, rgba(0, 0, 0, 0.3), transparent);
    transition: opacity ease-in-out 0.15s; }
    .Player__next:hover {
      opacity: 1; }
  .Player__plug {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 32px 24px 96px;
    cursor: default; }
    .Player__plug_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center; }
      @supports (backdrop-filter: blur(36px)) {
        .Player__plug_bg:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: '';
          background-color: rgba(0, 0, 0, 0.16);
          backdrop-filter: blur(36px); } }
      @supports not (backdrop-filter: blur(36px)) {
        .Player__plug_bg {
          filter: blur(28px); } }
    .Player__plug_body {
      position: relative;
      max-width: 280px; }
      @media (min-width: 641px) {
        .Player__plug_body {
          max-width: 310px; } }
    .Player__plug_icon {
      width: 72px;
      margin: 0 auto; }
      @media (min-width: 641px) {
        .Player__plug_icon {
          margin-bottom: 8px; } }
    .Player__plug_title {
      font-size: 32px;
      font-weight: 500;
      line-height: 1.3;
      text-align: center; }
      @media (max-width: 640px) {
        .Player__plug_title {
          font-size: 28px; } }
    .Player__plug .DraftsOffers {
      margin-top: 24px; }
    .Player__plug_bottom {
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 24px; }
    .Player__plug_cta {
      font-size: 17px;
      line-height: 1.74;
      font-weight: 500;
      box-sizing: border-box;
      line-height: 48px !important;
      display: inline-block;
      width: 100%;
      padding: 0 16px;
      text-align: center;
      color: #151529;
      border-radius: 100px;
      background-color: #FFFFFF;
      cursor: pointer;
      user-select: none;
      transition: opacity ease-in-out 0.15s; }
      @media (max-width: 640px) {
        .Player__plug_cta {
          font-size: 15px;
          line-height: 1.6; } }
      @media (hover: hover) {
        .Player__plug_cta:hover {
          opacity: .84; } }
