.Header {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  transition: top ease-in-out 0.15s, paddingBottom ease-in-out 0.15s;
  padding-right: 24px;
  padding-left: 24px;
  box-sizing: border-box; }
  @media (min-width: 481px) and (max-width: 640px) {
    .Header {
      padding-right: 72px;
      padding-left: 72px; } }
  @media (min-width: 641px) and (max-width: 1023px) {
    .Header {
      padding-right: 72px;
      padding-left: 72px; } }
  @media (min-width: 1024px) {
    .Header {
      padding-right: 128px;
      padding-left: 128px; } }
  @media (min-width: 1024px) and (max-width: 1199px) {
    .Header {
      padding-right: 72px;
      padding-left: 72px; } }
  @media (min-width: 641px) {
    .Header {
      padding-top: 32px;
      padding-bottom: 32px; } }
  .Header__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: #1F1F33;
    transition: opacity ease-in-out 0.15s; }
  .Header__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto; }
    @media (min-width: 1024px) {
      .Header__inner {
        max-width: 1184px; } }
  .Header__buttons {
    overflow: hidden;
    height: 64px;
    margin: -16px 0; }
    .Header__buttons_inner {
      padding: 16px 0; }
    .Header__buttons_content {
      display: flex;
      flex-direction: column;
      align-items: flex-end; }
  .Header__button {
    font-size: 15px;
    line-height: 1.5;
    font-weight: 500;
    line-height: 32px !important;
    display: inline-block;
    padding: 0 16px;
    border-radius: 100px;
    color: #FFFFFF;
    cursor: pointer;
    user-select: none; }
    @media (max-width: 640px) {
      .Header__button {
        font-size: 13px;
        line-height: 1.4; } }
    .Header__button--sign-in {
      background-color: rgba(255, 255, 255, 0.12);
      transition: background-color ease-in-out 0.15s;
      backdrop-filter: blur(72px); }
      @media (hover: hover) {
        .Header__button--sign-in:hover, .Header__button--sign-in:active {
          background-color: rgba(255, 255, 255, 0.24); } }
    .Header__button--buy {
      margin-top: 16px;
      z-index: 1;
      position: relative;
      overflow: hidden;
      user-select: none;
      cursor: pointer;
      background-image: linear-gradient(135deg, #9580FF 0%, #1D53BF 100%); }
      .Header__button--buy:before {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        opacity: 0;
        background-image: linear-gradient(135deg, #AA99FF 0%, #3060BF 100%);
        transition: opacity ease-in-out 0.15s; }
      @media (hover: hover) {
        .Header__button--buy:hover:before, .Header__button--buy:active:before {
          opacity: 1; } }
    .Header__button--watch {
      margin-top: 48px;
      z-index: 1;
      position: relative;
      overflow: hidden;
      user-select: none;
      cursor: pointer;
      background-image: linear-gradient(135deg, #45E6B0 0%, #36B374 100%); }
      .Header__button--watch:before {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        opacity: 0;
        background-image: linear-gradient(135deg, #55F2BE 0%, #43BF81 100%);
        transition: opacity ease-in-out 0.15s; }
      @media (hover: hover) {
        .Header__button--watch:hover:before, .Header__button--watch:active:before {
          opacity: 1; } }
