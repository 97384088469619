@media (max-width: 640px) {
  .PopupWrap--auth .PopupWrap__window {
    height: 100%; } }

.Popup {
  box-sizing: border-box;
  max-width: 800px;
  width: 100%;
  cursor: default;
  transition-property: opacity, transform;
  transition-timing-function: ease-in-out;
  animation-fill-mode: both; }
  @media (max-width: 640px) {
    .Popup {
      height: 100%; } }
  @media (min-width: 641px) {
    .Popup {
      padding: 32px;
      max-width: 864px; } }
  .Popup__inner {
    position: relative; }
    @media (max-width: 640px) {
      .Popup__inner {
        overflow: hidden;
        height: 100%;
        background-color: #151529; } }
  .Popup__close {
    z-index: 1;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    cursor: pointer; }
    @media (max-width: 640px) {
      .Popup__close {
        top: 16px;
        left: 16px; } }
    @media (min-width: 641px) {
      .Popup__close {
        top: 24px;
        left: 24px; } }
  @media (min-width: 641px) and (hover: hover) {
    .Popup__close:hover .Popup__close_bg, .Popup__close:active .Popup__close_bg {
      background-color: #27273D;
      transform: scale(1.2); } }
    .Popup__close_inner {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px; }
    .Popup__close_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #1F1F33;
      transition: transform ease-in-out 0.15s, background-color ease-in-out 0.15s; }
    .Popup__close_icon {
      position: relative;
      width: 16px;
      height: 16px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../../assets/icons/close_16.svg"); }
  .Popup__body {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #151529; }
    @media (min-width: 641px) {
      .Popup__body {
        position: relative;
        overflow: hidden;
        border-radius: 24px; } }
