.CoursePage .Content {
  position: relative;
  padding-top: 48px;
  padding-right: 24px;
  padding-left: 24px;
  box-sizing: border-box; }
  @media (min-width: 481px) and (max-width: 640px) {
    .CoursePage .Content {
      padding-right: 72px;
      padding-left: 72px; } }
  @media (min-width: 641px) and (max-width: 1023px) {
    .CoursePage .Content {
      padding-right: 72px;
      padding-left: 72px; } }
  @media (min-width: 1024px) {
    .CoursePage .Content {
      padding-right: 128px;
      padding-left: 128px; } }
  @media (min-width: 1024px) and (max-width: 1199px) {
    .CoursePage .Content {
      padding-right: 72px;
      padding-left: 72px; } }
  @media (min-width: 481px) and (max-width: 1023px) {
    .CoursePage .Content {
      padding-top: 72px; } }
  @media (min-width: 1024px) {
    .CoursePage .Content {
      padding-top: 96px; } }
  .CoursePage .Content__inner {
    width: 100%;
    margin: 0 auto; }
    @media (min-width: 1024px) {
      .CoursePage .Content__inner {
        max-width: 1184px; } }
    @media (min-width: 1024px) {
      .CoursePage .Content__inner {
        display: flex;
        align-items: flex-start;
        justify-content: space-between; } }
  @media (min-width: 1024px) {
    .CoursePage .Content__left {
      width: calc(29.73% * 2 + 5.4%); } }
