@media (max-width: 480px) {
  .CoursePage .Episodes {
    padding-top: 8px;
    margin-bottom: 48px; } }

@media (min-width: 481px) and (max-width: 1023px) {
  .CoursePage .Episodes {
    margin-bottom: 72px; } }

@media (max-width: 640px) {
  .CoursePage .Episodes__header {
    padding: 2px 0;
    margin-bottom: 24px; } }

@media (min-width: 641px) {
  .CoursePage .Episodes__header {
    padding: 3px 0; } }

@media (min-width: 641px) and (max-width: 1023px) {
  .CoursePage .Episodes__header {
    margin-bottom: 32px; } }

@media (min-width: 1024px) {
  .CoursePage .Episodes__header {
    margin-bottom: 48px; } }

.CoursePage .Episodes__title {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.3; }
  @media (max-width: 640px) {
    .CoursePage .Episodes__title {
      font-size: 28px; } }

@media (min-width: 641px) and (max-width: 1023px) {
  .CoursePage .Episodes__list {
    margin-top: 33px; } }

@media (min-width: 1024px) {
  .CoursePage .Episodes__list {
    margin-top: 49px; } }

.Episode:not(:last-of-type) {
  margin-bottom: 32px; }

@media (min-width: 481px) and (max-width: 1023px) {
  .Episode:not(:last-of-type) {
    margin-bottom: 48px; } }

@media (min-width: 641px) {
  .Episode {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; } }

@media (min-width: 1024px) {
  .Episode:not(:last-of-type) {
    margin-bottom: 64px; } }

.Episode__player {
  overflow: hidden;
  border-radius: 12px;
  -webkit-mask-image: -webkit-radial-gradient(white, black); }
  @media (max-width: 640px) {
    .Episode__player {
      margin-bottom: 16px; } }
  @media (min-width: 641px) and (max-width: 1023px) {
    .Episode__player {
      width: 47.56%; } }
  @media (min-width: 1024px) {
    .Episode__player {
      width: 45.83%; } }

.Episode__cover {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  border-radius: 12px; }
  @media (max-width: 640px) {
    .Episode__cover {
      margin-bottom: 16px; } }
  @media (min-width: 641px) and (max-width: 1023px) {
    .Episode__cover {
      width: 47.56%;
      padding-top: 26.83%; } }
  @media (min-width: 1024px) {
    .Episode__cover {
      width: 45.83%;
      padding-top: 25.78%; } }

.Episode__cover_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px 12px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.Episode__number, .Episode__duration {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  background-color: #1F1F33; }

.Episode__number {
  top: -3px;
  left: -3px;
  width: 32px;
  padding-top: 3px;
  padding-left: 3px;
  border-bottom-right-radius: 12px; }

.Episode__duration {
  right: -3px;
  bottom: -3px;
  padding: 0 15px 3px 12px;
  border-top-left-radius: 12px; }

.Episode__number_text, .Episode__duration_text {
  font-size: 15px;
  line-height: 1.5;
  font-weight: 500; }
  @media (max-width: 640px) {
    .Episode__number_text, .Episode__duration_text {
      font-size: 13px;
      line-height: 1.4; } }

@media (min-width: 641px) and (max-width: 1023px) {
  .Episode__info {
    width: 47.56%; } }

@media (min-width: 1024px) {
  .Episode__info {
    width: calc(54.17% - 32px);
    padding-top: 2px; } }

.Episode__title {
  font-size: 19px;
  line-height: 1.7;
  font-weight: 500; }
  @media (max-width: 640px) {
    .Episode__title {
      font-size: 17px;
      line-height: 1.6; } }
  @media (min-width: 641px) {
    .Episode__title {
      margin-bottom: 4px; } }

.Episode__description {
  font-size: 17px;
  line-height: 1.74;
  color: rgba(235, 235, 255, 0.64); }
  @media (max-width: 640px) {
    .Episode__description {
      font-size: 15px;
      line-height: 1.6; } }
