@media (max-width: 640px) {
  .DraftsPage .Drafts {
    margin-top: 24px; } }

@media (min-width: 641px) and (max-width: 1023px) {
  .DraftsPage .Drafts {
    margin-top: 72px; } }

@media (min-width: 1024px) {
  .DraftsPage .Drafts {
    width: calc(29.73% * 2 + 5.4%); } }

.DraftsPage .Drafts__list {
  font-size: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start; }
  @media (max-width: 480px) {
    .DraftsPage .Drafts__list {
      margin: 0 -25px -2px; } }
  @media (min-width: 481px) and (max-width: 640px) {
    .DraftsPage .Drafts__list {
      margin: 0 -8px -16px; } }
  @media (min-width: 641px) {
    .DraftsPage .Drafts__list {
      margin: 0 -16px -32px; } }

.DraftsPage .Drafts__draft {
  overflow: hidden;
  position: relative;
  display: block;
  width: calc(33.333% - 2px);
  padding-top: 49.6%;
  margin: 0 1px 2px;
  cursor: pointer; }
  @media (min-width: 481px) and (max-width: 640px) {
    .DraftsPage .Drafts__draft {
      width: calc(33.333% - 16px);
      padding-top: 46.15%;
      margin: 0 8px 16px; } }
  @media (min-width: 481px) {
    .DraftsPage .Drafts__draft {
      border-radius: 12px;
      -webkit-mask-image: -webkit-radial-gradient(white, black); } }
  @media (min-width: 641px) {
    .DraftsPage .Drafts__draft {
      width: calc(33.333% - 32px);
      margin: 0 16px 32px; } }
  @media (min-width: 641px) and (max-width: 1023px) {
    .DraftsPage .Drafts__draft {
      padding-top: 45.191%; } }
  @media (min-width: 1024px) {
    .DraftsPage .Drafts__draft {
      padding-top: 45.96%; } }
  @media (hover: hover) {
    .DraftsPage .Drafts__draft:hover .Drafts__draft_bg, .DraftsPage .Drafts__draft:active .Drafts__draft_bg {
      transform: scale(1.05); } }
  .DraftsPage .Drafts__draft--locked {
    pointer-events: none; }
    @supports (backdrop-filter: blur(36px)) {
      .DraftsPage .Drafts__draft--locked .Drafts__draft_bg:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: rgba(0, 0, 0, 0.16);
        backdrop-filter: blur(36px); } }
    @supports not (backdrop-filter: blur(36px)) {
      .DraftsPage .Drafts__draft--locked .Drafts__draft_bg {
        filter: blur(28px); } }
  .DraftsPage .Drafts__draft_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #1F1F33;
    transform-origin: center;
    transition: transform ease-in-out 0.15s;
    will-change: transform; }
  .DraftsPage .Drafts__draft_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
