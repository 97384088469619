.Footer {
  font-size: 17px;
  line-height: 1.74;
  margin-top: 48px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #1F1F33;
  padding-right: 24px;
  padding-left: 24px;
  box-sizing: border-box; }
  @media (max-width: 640px) {
    .Footer {
      font-size: 15px;
      line-height: 1.6; } }
  @media (min-width: 481px) and (max-width: 640px) {
    .Footer {
      padding-right: 72px;
      padding-left: 72px; } }
  @media (min-width: 641px) and (max-width: 1023px) {
    .Footer {
      padding-right: 72px;
      padding-left: 72px; } }
  @media (min-width: 1024px) {
    .Footer {
      padding-right: 128px;
      padding-left: 128px; } }
  @media (min-width: 1024px) and (max-width: 1199px) {
    .Footer {
      padding-right: 72px;
      padding-left: 72px; } }
  @media (max-width: 640px) {
    .Footer {
      padding-bottom: 12px; }
      @supports (padding-bottom: constant(safe-area-inset-bottom)) {
        .Footer {
          padding-bottom: calc(12px + constant(safe-area-inset-bottom)); } }
      @supports (padding-bottom: env(safe-area-inset-bottom)) {
        .Footer {
          padding-bottom: calc(12px + env(safe-area-inset-bottom)); } } }
  @media (min-width: 481px) and (max-width: 1023px) {
    .Footer {
      margin-top: 72px; } }
  @media (min-width: 641px) {
    .Footer {
      padding-top: 16px;
      padding-bottom: 16px; } }
  @media (min-width: 1024px) {
    .Footer {
      margin-top: 128px; } }
  .Footer__inner {
    display: flex;
    width: 100%;
    margin: 0 auto; }
    @media (min-width: 1024px) {
      .Footer__inner {
        max-width: 1184px; } }
    @media (max-width: 480px) {
      .Footer__inner {
        flex-direction: column-reverse; } }
    @media (min-width: 481px) {
      .Footer__inner {
        align-items: flex-start;
        justify-content: space-between; } }
  .Footer__item {
    padding: 8px 0; }
    @media (min-width: 641px) {
      .Footer__item {
        padding: 19px 16px 16px; } }
  @media (min-width: 481px) and (max-width: 640px) {
    .Footer__copyrights {
      width: 50%; } }
  @media (min-width: 641px) {
    .Footer__copyrights {
      margin-left: -16px; } }
  @media (max-width: 480px) {
    .Footer__links {
      margin-bottom: 12px; } }
  @media (min-width: 481px) and (max-width: 640px) {
    .Footer__links {
      width: 50%; } }
  @media (min-width: 481px) {
    .Footer__links {
      margin-right: -16px; } }
  @media (min-width: 641px) {
    .Footer__links {
      display: flex;
      align-items: center; } }
  .Footer__link {
    font-size: 17px;
    line-height: 1.74;
    color: #FFFFFF;
    transition: opacity ease-in-out 0.15s; }
    @media (max-width: 640px) {
      .Footer__link {
        font-size: 15px;
        line-height: 1.6; } }
    @media (hover: hover) {
      .Footer__link:hover, .Footer__link:active {
        opacity: .64; } }
