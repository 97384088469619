.PaymentWays {
  display: inline-flex;
  align-items: center; }
  .PaymentWays__way {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 32px;
    margin: 0 8px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(72px); }
