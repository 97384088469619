.PopupForm {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-width: 480px;
  text-align: center; }
  @media (max-width: 640px) {
    .PopupForm {
      max-width: 462px;
      padding: 72px 24px; } }
  @media (min-width: 641px) {
    .PopupForm {
      display: flex;
      align-items: center;
      padding: 72px 32px; } }
  @media (min-width: 1024px) {
    .PopupForm {
      padding-top: 128px;
      padding-bottom: 128px; } }
  .PopupForm__inner {
    z-index: 1;
    position: relative;
    width: 100%; }
  .PopupForm__icon {
    width: 84px;
    margin: 0 auto 32px; }
    @media (max-width: 640px) {
      .PopupForm__icon {
        margin-bottom: 24px; } }
  .PopupForm__header {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.3;
    max-width: 368px;
    margin: 0 auto; }
    @media (max-width: 640px) {
      .PopupForm__header {
        font-size: 28px; } }
    .PopupForm__state--success .PopupForm__header,
    .PopupForm__state--error .PopupForm__header {
      margin-bottom: 8px; }
  .PopupForm__footer {
    font-size: 17px;
    line-height: 1.74;
    max-width: 368px;
    margin: 0 auto; }
    @media (max-width: 640px) {
      .PopupForm__footer {
        font-size: 15px;
        line-height: 1.6; } }
    .PopupForm__state--success .PopupForm__footer,
    .PopupForm__state--error .PopupForm__footer {
      color: rgba(235, 235, 255, 0.64); }
  .PopupForm__ball {
    position: absolute;
    border-radius: 50%;
    background: radial-gradient(100% 100% at 0% 0%, #9580FF 0%, #1D38BF 100%); }
